import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  getBonafideInsuranceInfo,
  submitBonafideOrder
} from "features/bonafide/actions";
import { InlineWaiting } from "components/ui";
import { formatDate } from "utils/dates";
// import {
//   getRemainingDeductible
//   //getPatientIsEligible
// } from "features/bonafide/helpers";
import { formatMoney } from "accounting";
import isEmpty from "lodash/isEmpty";
import { MdRefresh } from "react-icons/lib/md";
import { useFetch } from "hooks";
import { post, get } from "utils/api";
import { errorMessage } from "actions/message";

function getStatusTextColor() {
  return "";
}

const BonafideInsuranceVerificationPanel = ({
  visible,
  getBonafideInsuranceInfo,
  companyInfo,
  orderId,
  errorMessage
}) => {
  const [index, setIndex] = React.useState(0);
  const initData = async () => {
    await getBonafideInsuranceInfo(orderId);
  };

  React.useEffect(() => {
    initData();
    setIndex(0);
  }, [orderId]);

  const insuranceVerificationAPI = useFetch({
    apiFn: orderId =>
      post(`bonafide/insurance-verification/${orderId}`, { orderId }),
    onSuccess: () => getVerificationInfoAPI.fetchData(orderId),
    onError: err => errorMessage(err.message)
  });

  const getVerificationInfoAPI = useFetch({
    apiFn: orderId => get(`bonafide/verification-info/${orderId}`),
    defaultValue: [],
    onError: err => errorMessage(err.message)
  });
  React.useEffect(() => {
    getVerificationInfoAPI.fetchData(orderId);
  }, [orderId]);

  const verificationHistory = getVerificationInfoAPI.response ?? [];

  const bonafideSelectedResult = verificationHistory?.[index] ?? {};
  if (!companyInfo?.eligible_for_insurance_verification)
    return (
      <div
        className={`bonafide-insurance-verification ${visible ? "" : "hidden"}`}
      >
        <div
          style={{
            padding: 20,
            marginTop: "20px",
            gridColumn: "1/-1",
            fontWeight: "bold",
            color: "#666",
            fontSize: "1rem"
          }}
        >
          Not Eligible for Insurance Verification
        </div>
      </div>
    );
  return (
    <div
      className={`bonafide-insurance-verification ${visible ? "" : "hidden"}`}
    >
      <div
        className="bonafide-panel-header"
        style={{
          padding: 5,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gridGap: 10,
          alignItems: "center",
          borderBottom: "1px solid #ccc"
        }}
      >
        <label>History: </label>
        <select onChange={e => setIndex(e.target.value)} style={{ margin: 0 }}>
          {verificationHistory?.map((x, index) => (
            <>
              {index == 1 && (
                <option disabled>
                  ----------------------------------------------------------------
                </option>
              )}
              <option key={x.id} value={index}>
                {formatDate(x.requestedAt, "L hh:mm A")} -{" "}
                {"Order Verification"} - {x.response?.status}{" "}
              </option>
            </>
          ))}
        </select>
      </div>
      <div className="bonafide-result-container">
        <div className="bonafide-status-result-container">
          {bonafideSelectedResult?.response?.status && (
            <React.Fragment>
              <div className="bonafide-overview">
                <div className="status-label" style={{ alignItems: "center" }}>
                  <div>Transactions:</div>
                  <div
                    className={`status-text`}
                    style={{
                      color: getStatusTextColor(
                        bonafideSelectedResult?.response?.status
                      )
                    }}
                  >
                    {bonafideSelectedResult?.response?.status}
                  </div>
                </div>
              </div>
              <div className="status-label">
                <div style={{ fontSize: "1.05em" }}>Eligibility:</div>
                <div
                  className={`status-text`}
                  style={{
                    color:
                      bonafideSelectedResult?.response?.eligStatus ===
                      "InActivity"
                        ? "orange"
                        : "#222",
                    display: "flex",
                    alignItems: "flex-end",
                    fontSize: "1.15em"
                  }}
                >
                  {bonafideSelectedResult?.response?.eligStatus}
                </div>
              </div>

              {bonafideSelectedResult.response?.eligibility && (
                <div
                  style={{
                    marginLeft: "1.3em",
                    padding: 5
                  }}
                >
                  <div>
                    {bonafideSelectedResult.response?.eligibility["271Status"]}
                  </div>
                  <div>
                    {bonafideSelectedResult.response?.eligibility?.ebeligStatus}
                  </div>
                  <div>
                    {bonafideSelectedResult.response?.eligibility?.message}
                  </div>
                  {bonafideSelectedResult.response?.eligibility
                    ?.remainingDeductible && (
                    <div>
                      Remaining Deductible :{" "}
                      {
                        bonafideSelectedResult.response?.eligibility
                          ?.remainingDeductible
                      }
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <React.Fragment>
          <div className="bonafide-errors-container">
            <div className="reason-status-text">
              {bonafideSelectedResult?.response?.reason}
            </div>
            {bonafideSelectedResult.response?.errors &&
              bonafideSelectedResult.response?.errors
                .filter(e => !!e)
                .map(s => (
                  <div key={s} className="bonafide-issue">
                    {s}
                  </div>
                ))}
          </div>
          <div className="bonafide-eq-info">
            {bonafideSelectedResult &&
              bonafideSelectedResult.response?.order && (
                <div>
                  {JSON.stringify(bonafideSelectedResult?.response?.order)}
                </div>
              )}
            {/*bonafideSelectedResult && bonafideSelectedResult.order && (
              <React.Fragment>
                {bonafideSelectedResult.order.lineItems && (
                  <TotalOOPInfo
                    lineItems={bonafideSelectedResult.order.lineItems}
                    remainingDeductible={getRemainingDeductible(
                      bonafideSelectedResult
                    )}
                    patientIsEligible={null}
                  />
                )}
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>HCPCS</th>
                      <th>Allowable</th>
                      <th>OOP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bonafideSelectedResult.order.lineItems &&
                      bonafideSelectedResult.order.lineItems.map(
                        ({ itemName, hcpcs, allowable }, index) => (
                          <tr key={`${itemName}-${index}`}>
                            <td>{itemName}</td>
                            <td>{hcpcs}</td>
                            <td>
                              {allowable === null
                                ? null
                                : formatMoney(allowable)}
                            </td>
                            <td>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </React.Fragment>
            )*/}
          </div>
        </React.Fragment>
      </div>
      <div className="bonafide-footer">
        {getBonafideInsuranceInfo.isFetching ? (
          <InlineWaiting />
        ) : (
          <React.Fragment>
            <i
              title="Refresh"
              className="refresh-button"
              onClick={() => getVerificationInfoAPI.fetchData(orderId)}
            >
              <MdRefresh />
            </i>
            <div className={`bonafide-request-order-info`}>
              {insuranceVerificationAPI.isFetching ? (
                <InlineWaiting />
              ) : (
                <button
                  className="step-label"
                  style={{ margin: 0, padding: 5 }}
                  onClick={() => insuranceVerificationAPI.fetchData(orderId)}
                >
                  Verify
                </button>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

BonafideInsuranceVerificationPanel.propTypes = {
  orderId: PropTypes.string.isRequired,
  errorMessage: PropTypes.func.isRequired,
  getBonafideInsuranceInfo: PropTypes.func.isRequired,
  companyInfo: PropTypes.object.isRequired,
  //orderLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  //isValid: PropTypes.bool,
  patientId: PropTypes.string,
  visible: PropTypes.bool
};

export default connect(
  (state, { orderId }) => {
    const { data: orderLineItems } = selectors.getOrderLineItems(state, {
      orderId
    });

    /*
    const { data: patientInfo } = selectors.getOrderPatientInfo(state, {
      orderId
    });
    */
    return {
      orderLineItems
      //patientId: patientInfo?.patient_id,
      /*isValid: selectors.getIsValidBonafideResult(
        state,
        {
          orderId
        },
        orderLineItems
      ),*/
    };
  },
  {
    getBonafideInsuranceInfo,
    submitBonafideOrder,
    errorMessage
    //requestOrderHoldReasons
  }
)(BonafideInsuranceVerificationPanel);

const TotalOOPInfo = ({
  lineItems,
  remainingDeductible,
  patientIsEligible
}) => {
  const { patientResponsiblity, allowable } = lineItems.reduce(
    (acc, { allowable, patientResponsiblity }) => ({
      allowable: parseFloat(acc.allowable) + parseFloat(allowable),
      patientResponsiblity:
        parseFloat(acc.patientResponsiblity) + parseFloat(patientResponsiblity)
    }),
    { patientResponsiblity: 0, allowable: 0 }
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        padding: 10,
        borderBottom: "1px solid #ddd"
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: 600, paddingRight: 10, fontSize: "1.05em" }}>
          Total Allowable:
        </div>
        {formatMoney(allowable)}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: 600, paddingRight: 10, fontSize: "1.05em" }}>
          Total OOP:
        </div>
        {!patientIsEligible || isNaN(patientResponsiblity) ? (
          <span style={{ color: "#888" }}>Not Verified</span>
        ) : (
          formatMoney(patientResponsiblity)
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: 600, paddingRight: 10, fontSize: "1.05em" }}>
          Deductible:
        </div>
        {!patientIsEligible || isEmpty(remainingDeductible)
          ? ""
          : formatMoney(remainingDeductible)}
      </div>
    </div>
  );
};

TotalOOPInfo.defaultProps = { lineItems: [] };
TotalOOPInfo.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.object),
  remainingDeductible: PropTypes.string,
  patientIsEligible: PropTypes.bool
};
