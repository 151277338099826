import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "hooks";
import MdArrowUp from "react-icons/lib/md/arrow-upward";
import MdArrowDown from "react-icons/lib/md/arrow-downward";
import { connect } from "react-redux";
import {
  requestOrderHoldReasons,
  updateOrderHoldReason
} from "actions/order-profile";
import ResizePanel from "./resize-panel";
import { selectors } from "reducers";
import BonafideOrderPanel from "features/bonafide/components/order-profile";
import BonafideInsuranceVerification from "features/bonafide/components/insurance-verification";
import OrderWorklist, { OrderClipboardOptions } from "./worklist";
import * as R from "ramda";
import OrderTasks from "./tasks";

const OrderClipboard = ({
  orderId,
  notes,
  companyInfo,
  order_hold_reasons,
  requestOrderHoldReasons,
  patientInfo,
  orderStatus,
  updateOrderHoldReason
}) => {
  const [isMinimized, setIsMinizmized] = useLocalStorage(
    "worklist-minimized",
    true
  );
  const [containerHeight, setContainerHeight] = useLocalStorage(
    "order-worklist-panel-height",
    115
  );
  const [selectedClipboardOption, setClipboardOption] = useLocalStorage(
    "order-worklist-radio-view",
    "all"
  );
  useEffect(() => {
    requestOrderHoldReasons(orderId);
  }, [orderId]);
  const clipboardItems = R.sortWith(
    [R.descend(R.prop("created_at"))],
    [
      ...(selectedClipboardOption !== "hold-reasons" ? notes : []),
      ...(selectedClipboardOption !== "notes"
        ? order_hold_reasons.map(hr => ({
            ...hr,
            created_at: hr.createdDate
          }))
        : [])
    ]
  );
  function handleTabClick(tabLabel) {
    if (tabLabel === selectedTab) {
      setIsMinizmized(() => !isMinimized);
    } else {
      setIsMinizmized(false);
    }
    setSelectedTab(tabLabel);
  }
  const [selectedTab, setSelectedTab] = React.useState("tasks");
  return (
    <React.Fragment>
      <div className="work-list-container">
        <div className={`work-list-header ${isMinimized ? "minimized" : ""}`}>
          <div className="header-text">
            <div className="order-worklist-tab-headers">
              <div
                className={`header-tab ${
                  selectedTab === "tasks" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tasks")}
              >
                Clipboard
              </div>
              {companyInfo?.insurance_verification_type == "Bonafide" && (
                <div
                  className={`header-tab ${
                    selectedTab === "bonafide" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("bonafide")}
                >
                  Verify
                </div>
              )}
              {companyInfo?.insurance_verification_type == "BonafideInsuranceVerification" && (
                <div
                  className={`header-tab ${
                    selectedTab === "bonafideInsVerification" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("bonafideInsVerification")}
                >
                  Verify Insurance
                </div>
              )}
            </div>
          </div>
          {selectedTab === "clipboard" && (
            <OrderClipboardOptions
              selectedClipboardOption={selectedClipboardOption}
              setClipboardOption={setClipboardOption}
              noteCount={notes.length}
              order_hold_reasons={order_hold_reasons}
            />
          )}
          <div
            className="minimize-order-worklist"
            onClick={() => setIsMinizmized(() => !isMinimized)}
          >
            <i className="flat-icon-button ripple">
              {isMinimized ? (
                <MdArrowDown size={16} />
              ) : (
                <MdArrowUp size={16} color="#666" />
              )}
            </i>
          </div>
        </div>
        {!isMinimized && (
          <React.Fragment>
            <div
              className="work-list-reasons scrollbar-dark"
              style={{
                height: containerHeight,
                minHeight: 65,
                maxHeight: "75vh"
              }}
            >
              {selectedTab === "clipboard" && (
                <OrderWorklist
                  orderId={orderId}
                  clipboardItems={clipboardItems}
                  order_hold_reasons={order_hold_reasons}
                  selectedClipboardOption={selectedClipboardOption}
                  orderStatus={orderStatus}
                  updateOrderHoldReason={updateOrderHoldReason}
                />
              )}
              {selectedTab === "tasks" && (
                <OrderTasks
                  orderId={orderId}
                  orderStatus={orderStatus}
                  patientId={patientInfo?.patient_id}
                  notes={notes}
                />
              )}
              {companyInfo?.insurance_verification_type == "Bonafide" && (
                <BonafideOrderPanel
                  orderId={orderId}
                  visible={selectedTab === "bonafide"}
                />
              )}
              {companyInfo?.insurance_verification_type == "BonafideInsuranceVerification" && (
                <BonafideInsuranceVerification
                  orderId={orderId}
                  visible={selectedTab === "bonafideInsVerification"}
                  companyInfo={companyInfo}
                />
              )}
            </div>
            <ResizePanel
              isMinimized={isMinimized}
              containerHeight={containerHeight}
              setContainerHeight={setContainerHeight}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, { orderId }) => {
    const { data: notes } = selectors.getOrderNotes(state, { orderId });
    const { data: orderInfo = {} } = selectors.getOrderInfo(state, { orderId });
    const { data: patientInfo } = selectors.getOrderPatientInfo(state, {
      orderId
    });
    const { data: companyInfo } = selectors.getOrderCompanyInfo(state, { orderId });
    return {
      notes,
      orderInfo,
      companyInfo,
      patientInfo,
      orderStatus: selectors.getEnumOrderStatusText(state, orderInfo.status),
      order_hold_reasons: selectors.getOrderHoldReasons(state, orderId)
    };
  },
  { requestOrderHoldReasons, updateOrderHoldReason }
)(OrderClipboard);

OrderClipboard.defaultProps = {
  order_hold_reasons: [],
  notes: []
};

OrderClipboard.propTypes = {
  orderStatus: PropTypes.string,
  requestOrderHoldReasons: PropTypes.func.isRequired,
  updateOrderHoldReason: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  order_hold_reasons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.string
    })
  ),
  orderId: PropTypes.string.isRequired,
  companyInfo: PropTypes.object.isRequired,
  patientInfo: PropTypes.object
};
